.news-form {
    display: flex;
    flex-direction: column;
    gap: 2vmax;
    margin-bottom: 6vmax;

    .buttons-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 2vmax;

        > * {
            border: none;
            font-size: 1.1rem;
            cursor: pointer;

            &:hover {
                opacity: 0.8;
            }
        }

        .cancel-button {
            background-color: white;
            color: $secondary-color;

            &:hover {
                text-decoration: underline;
            }
        }

        .submit-button {
            display: flex;
            align-items: center;
            gap: 0.5vmax;
            padding: 0.7vmax 1vmax;
            background-color: $secondary-color;
            color: white;
            border-radius: 0.35vmax;
        }
    }

    > * {
        width: calc(90% + 14px);
    }

    .share-date-container {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        gap: 1.5vmax;
        color: $gray;
    }

    .title-field {
        .form-field {
            font-weight: 700;
            font-size: 2.5rem;
        }
    }

    .description-field {
        .form-field {
            font-size: 1.3rem;
            font-weight: 300;
            text-align: justify;
            line-height: 1.5rem;
        }
    }

    .video-field {
        .form-field {
            font-size: 1.3rem;
            font-weight: 300;
        }
    }

    .principal-field {
        label {
            font-size: 1.3rem;
            font-weight: 550;
            color: $secondary-color;
            margin-right: 1vmax;
        }
    }
}
