header {
    padding: 1vmax;
    background-color: $main-color;

    .header-top {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 3vmax;

        > * {
            flex: 33.3% 0;
            justify-content: center;
        }

        .search {
            position: relative;
            display: flex;
            gap: 0.5vmax;
            align-items: center;

            .search-input {
                background-color: transparent;
                border: none;
                outline: none;
                color: white;
                text-align: right;
                padding-bottom: calc(0.2vmax + 1px);

                &:focus {
                    border-bottom: 1px solid white;
                    padding-bottom: 0.2vmax;
                }
            }

            svg {
                cursor: pointer;

                &:hover {
                    transition: 0.2s;
                    opacity: 0.7;
                }
            }

            .user-operations {
                position: absolute;
                right: 10%;

                &:hover {
                    .operations {
                        display: flex;
                    }
                }

                .operations {
                    display: none;
                    flex-direction: column;
                    position: absolute;
                    padding: 0.2vmax 0.5vmax;
                    right: 0;
                    background-color: $light-gray;

                    .operation-item {
                        width: 100%;
                        padding: 0.5vmax 1vmax;
                        cursor: pointer;
                        color: $gray;
                        text-decoration: none;
                        white-space: nowrap;

                        &:hover {
                            text-decoration: underline;
                        }

                        &:not(:first-child) {
                            border-top: 0.15vmax solid $light-gray-2;
                        }
                    }
                }
            }
        }
    }

    .header-nav {
        display: flex;
        justify-content: center;

        .links-container {
            gap: 15vmax;
        }
    }
}

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vmax 5vmax;
    background-color: $main-color;

    .footer-menu {
        display: flex;
        justify-content: center;
        margin-top: 1.5vmax;
        padding: 0 3vmax;
        padding-top: 0.5vmax;
        border-top: 0.3vmax solid white;

        .links-container {
            gap: 5vmax;
        }
    }

    .social-links {
        align-self: flex-start;
    }

    .copyright {
        color: white;
        font-size: 1.08rem;
    }
}

.links-container {
    margin: 0;
    padding: 0.3vmax 1vmax;
    list-style: none;
    display: flex;
    justify-content: center;

    .link {
        color: white;
        font-size: 1.3rem;
        text-decoration: none;

        &.selected {
            border-bottom: 0.2rem solid white;
        }

        &:not(.selected):hover {
            text-decoration: underline;
        }
    }
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        width: 8vmax;
    }
}

.social-links {
    display: flex;
    align-items: center;
    gap: 2vmax;

    a {
        &:hover {
            transition: 0.2s;
            opacity: 0.7;
        }
    }
}

@media only screen and (max-width: 800px) {
    .links-container {
        .link {
            font-size: 1rem;
        }
    }

    footer {
        .social-links {
            margin: 1vmax 0;
            align-self: center;
        }
    }
}

@media only screen and (max-width: 900px) {
    .user-operations {
        position: absolute;
        top: 150%;
        right: 10%;
    }
}

@media only screen and (max-width: 550px) {
    header {
        .header-top {
            margin: 0;
            padding-right: 1vmax;
            justify-content: space-between;

            .social-links {
                display: none;
            }
        }

        .header-nav {
            .links-container {
                width: 100%;
                flex-direction: column;
                align-items: center;
                gap: 0;

                > * {
                    width: 70%;
                    padding: 1vmax;
                    text-align: center;

                    &:not(:last-child) {
                        border-bottom: 0.2vmax solid $light-gray;
                    }
                }

                .link {
                    font-size: 1.3rem !important;
                }
            }
        }
    }

    footer {
        .footer-menu {
            padding: 0;
            padding-top: 0.5vmax;
        }
    }
}
