@import url("https://fonts.googleapis.com/css2?family=Karla:wght@300;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

@import "./variables.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body,
#root,
.App {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    font-family: $main-font;
    font-style: normal;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
    font-size: 1rem;

    .page-container {
        padding: 4vmax 7vmax;

        .content-container {
            display: flex;
            gap: 8%;

            .content-title {
                position: relative;
                padding: 1.8rem 0;
                margin-bottom: 3vmax;
                border-bottom: 0.4vmax solid $light-gray;

                .title {
                    position: absolute;
                    font-size: 2.2rem;
                    min-width: 15vmax;
                    bottom: -0.4vmax;
                    padding-bottom: 0.4vmax;
                    border-bottom: 0.4vmax solid $secondary-color;
                }

                .add-container {
                    position: absolute;
                    bottom: -0.4vmax;
                    right: 0;
                    padding-bottom: 0.8vmax;
                    display: flex;
                    align-items: center;
                    gap: 0.7vmax;
                    color: $gray;
                    font-weight: 550;
                    font-size: 1rem;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        opacity: 0.7;
                        text-decoration: underline;
                    }
                }
            }

            .content {
                flex: 74%;
            }

            .ads-sidebar {
                flex: 18%;
            }
        }
    }

    .news-article,
    .last-news-article {
        display: flex;
        gap: 3%;
        align-items: center;

        .image-container {
            flex: 57%;

            .no-image {
                aspect-ratio: 345/250;
            }
        }

        .info-container {
            flex: 43%;
            display: flex;
            flex-direction: column;
            gap: 0.7vmax;
            justify-content: center;
            font-size: 1.4rem;

            .date {
                color: $gray;
            }

            .title {
                font-size: 1.8rem;
                font-weight: 700;
                line-height: 2rem;
                margin-bottom: 0.8vmax;
            }

            .description {
                font-size: 1.35rem;
                font-weight: 300;
            }

            .options {
                position: relative;
                display: flex;

                .more {
                    display: flex;
                    align-items: center;
                    font-size: 1.3rem;
                    gap: 0.7vmax;
                    font-weight: 550;
                    color: $secondary-color;
                    cursor: pointer;
                    text-decoration: none;

                    &:hover {
                        svg {
                            transition: 0.2s;
                            margin-left: 1vmax;
                        }
                    }
                }

                .action-buttons {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    display: flex;
                    align-items: center;
                    gap: 1vmax;

                    .edit-icon-container {
                        padding-top: 0.5rem;
                    }

                    .tiny-static-plus-icon {
                        cursor: pointer;

                        &:hover {
                            opacity: 0.7;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .image-container {
        display: flex;
        align-items: center;
        height: fit-content;

        img {
            width: 100%;
        }

        .no-image {
            background-color: lightgray;
            width: 100%;
        }
    }

    .loading-container {
        width: 100%;
        height: 100%;
        display: flex;
        padding-top: 10%;
        padding-bottom: 10%;
        justify-content: center;

        .loading-component {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1vmax;

            span {
                font-size: 1.5rem;
                font-weight: 550;
                color: $secondary-color;
            }
        }
    }

    .empty {
        width: 100%;
        font-size: 1.3rem;
        text-align: center;
    }

    .pagination-container {
        display: flex;
        align-items: flex-end;
        gap: 1vmax;
        margin: auto;
        padding-bottom: 0.3vmax;
        color: $gray;
        font-size: 1.5rem;
        max-width: 50vmax;
        overflow-x: auto;
        overflow-y: hidden;

        > *:not(.middle-pages) {
            position: relative;
            padding: 1.4rem 1.5rem;
            cursor: pointer;

            &.actual {
                font-weight: 550;
                color: white;
                background-color: $secondary-color;
                border-radius: 0.35vmax;
            }

            &:not(.actual):hover {
                color: $secondary-color;
            }

            &[class^="page"]:hover {
                font-weight: 550;
            }

            span {
                position: absolute;
                top: 50%;
                height: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .middle-pages {
            font-size: 1.5rem;
        }

        .next-page,
        .prev-page {
            font-size: 2.5rem;

            span {
                transform: translate(-50%, -100%);
            }
        }
    }

    .ads-sidebar {
        position: relative;
        overflow-y: hidden;

        .ads-container {
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            gap: 2vmax;

            .ads-article {
                overflow: hidden;

                .no-image {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

    .ads-bottom {
        width: 100%;
        aspect-ratio: 728/90;

        .no-image {
            background-color: lightgray;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .info-container {
        position: relative;

        .options {
            position: unset !important;

            .action-buttons {
                top: unset !important;
                bottom: 0% !important;
                transform: translateY(85%) !important;
            }
        }
    }
}

@media only screen and (max-width: 750px) {
    .pagination-container {
        max-width: 100%;
    }

    .content-container {
        align-self: unset;
        flex-direction: column;
    }
}
