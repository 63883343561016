.news-detail-page {
    .content {
        .action-buttons {
            display: flex;
            gap: 2.5vmax;
            padding-bottom: 1vmax;
            font-weight: 550;
            font-size: 1rem;

            .edit-container,
            .remove-container {
                display: flex;
                align-items: center;
                gap: 0.3vmax;
                cursor: pointer;
                text-decoration: none;
                color: $gray;

                &:hover {
                    opacity: 0.7;
                    text-decoration: underline;
                }
            }
        }
    }

    .news-detail {
        display: flex;
        flex-direction: column;
        gap: 2vmax;
        margin-bottom: 6vmax;

        > * {
            width: 90%;
        }

        .image-container {
            width: 100%;

            img {
                min-width: 100%;
            }
        }

        .share-date-container {
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            gap: 1.5vmax;
            color: $gray;
        }

        .title {
            font-weight: 700;
            font-size: 2.5rem;
        }

        .description {
            font-size: 1.7rem;
            font-weight: 300;
            line-height: 2.5rem;
            white-space: pre-wrap;

            .ads-article {
                margin: auto;
                padding: 0;

                &.type-0,
                &.type-1 {
                    max-width: 40%;
                }

                &.type-2,
                &.type-4 {
                    max-width: 95%;
                }

                &.type-3 {
                    max-width: 20%;
                }
            }
        }

        .video {
            width: 100%;
            display: flex;
            justify-content: center;

            iframe {
                width: 60%;
                height: auto;
                aspect-ratio: 1267/712;
            }
        }
    }
}
