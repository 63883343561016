.calendar-page {
    .page-container {
        .content-container {
            .content {
                margin-top: 2vmax;

                .events-container {
                    display: flex;
                    flex-direction: column;
                    gap: 3vmax;
                    margin-bottom: 3vmax;

                    .event-article {
                        display: flex;
                        align-items: center;
                        gap: 1vmax;
                        padding-bottom: 3vmax;

                        &:not(:last-of-type) {
                            border-bottom: 0.2vmax solid $light-gray;
                        }

                        .image-container {
                            flex: 40%;

                            .no-image {
                                aspect-ratio: 353/196;
                            }
                        }

                        .event-content {
                            flex: 60%;
                            display: flex;
                            flex-direction: column;
                            gap: 1vmax;

                            .title {
                                font-size: 1.6rem;
                                font-weight: 550;
                                line-height: 150%;
                                letter-spacing: 1px;
                            }

                            .description {
                                font-size: 1.2rem;
                                font-weight: 300;
                                white-space: pre-wrap;
                            }

                            .date {
                                position: relative;
                                font-size: 1.1rem;
                                margin-left: 1vmax;
                                font-weight: 300;

                                .action-buttons {
                                    top: 50%;
                                    transform: translateY(-50%);
                                    position: absolute;
                                    right: 0;
                                    display: flex;
                                    gap: 1vmax;

                                    .tiny-static-plus-icon {
                                        cursor: pointer;

                                        &:hover {
                                            opacity: 0.7;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .events-container {
        .event-article {
            flex-direction: column;

            .image-container {
                width: 100%;
            }
        }
    }
}
