.auth-page {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 66.7%;

    .logo-container {
        background-color: $secondary-color;
        padding: 4vmax;

        h2 {
            margin-top: 1vmax;
            color: white;
            font-size: 1.5vmax;
            font-style: italic;
        }

        p {
            color: white;
            font-size: 1.5rem;
            margin-top: 1vmax;
        }
    }
}
