.home-page {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 3vmax 6.5vmax;
    padding-bottom: 6vmax;

    .main-news-article {
        width: 100%;
        position: relative;
        background-color: lightgray;

        .main-news {
            text-decoration: none;

            .image-container {
                aspect-ratio: 1242/519;
                overflow: hidden;

                img {
                    width: 100%;
                }

                .no-image {
                    width: 100%;
                    height: 100%;
                }
            }

            .title {
                position: absolute;
                width: 100%;
                bottom: 0;
                padding: 2.5vmax 2vmax;
                padding-bottom: 1.6vmax;
                background-image: linear-gradient(rgba(154, 0, 24, 0) 0%, $main-color 100%);
                color: white;
                font-size: 1.7rem;
                font-weight: bold;
                line-height: 29px;
                letter-spacing: 2px;

                span {
                    display: block;
                    max-width: 50%;
                }
            }
        }
    }

    .page-container {
        display: flex;
        gap: 10%;
        padding: 0;
        margin-top: 6vmax;
        margin-bottom: 5vmax;

        .main-sidebar {
            flex: 34%;
        }

        .content-container {
            flex: 66%;
            display: flex;
            flex-direction: column;
            align-self: flex-start;

            .banner-img {
                display: flex;
                max-width: 100%;
                box-shadow: $basic-box-shadow;
            }

            .news-container {
                padding-top: 1vmax;

                .content-title {
                    margin-top: 2vmax;
                    margin-bottom: 2vmax;
                }

                .news-list {
                    display: flex;
                    flex-direction: column;
                    gap: 4vmax;
                    padding-top: 2vmax;
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {
    .news-list {
        gap: 6vmax !important;
    }

    .last-news {
        gap: 4vmax !important;
    }

    .news-article,
    .last-news-article {
        position: relative;
        flex-direction: column;

        &.news-article {
            .options {
                width: 70%;
            }

            .date {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }

        &.last-news-article {
            .info-container {
                flex-direction: column-reverse;
            }
        }

        .image-container {
            width: 100%;
        }

        .info-container {
            width: 100%;
            padding-top: 1vmax;
        }
    }
}

@media only screen and (max-width: 800px) {
    .home-page {
        padding: 0;
        padding-bottom: 3vmax;

        .main-news {
            .image-container {
                aspect-ratio: 1242/519;
            }

            .title {
                background-image: none !important;
                background-color: rgba(0, 0, 0, 0.8) !important;
                position: relative !important;

                span {
                    max-width: 100% !important;
                }
            }
        }

        .page-container {
            padding: 0 6.5vmax;
        }

        > .ads-bottom {
            padding: 0 6.5vmax;
        }
    }

    .page-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 500px) {
    .home-page {
        .main-news {
            .image-container {
                aspect-ratio: 872/519 !important;
            }
        }
    }
}
