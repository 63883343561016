// MIXINS

@mixin unselect {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -khtml-user-select: none; /* webkit (konqueror) browsers */
    -ms-user-select: none; /* IE10+ */
}

// SCROLL

.scroll::-webkit-scrollbar {
    position: relative;
    height: 0.5rem;
}

.scroll::-webkit-scrollbar-track {
    position: absolute;
    background-color: $light-gray;
    border-radius: 100px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: $light-gray-2;
}

// ICONS

.large-icon {
    @include unselect;
    width: 12vmax;
}

.medium-icon {
    @include unselect;
    width: 3vmax;
}

.medium-static-icon {
    @include unselect;
    width: 2.4rem;
}

.medium-less-icon {
    @include unselect;
    width: 2.4vmax;
}

.small-icon {
    @include unselect;
    width: 2vmax;
}

.small-static-icon {
    @include unselect;
    width: 1.8rem;
}

.small-less-icon {
    @include unselect;
    width: 1.5vmax;
}

.tiny-plus-icon {
    @include unselect;
    width: 1.2vmax;
}

.tiny-static-plus-icon {
    @include unselect;
    width: 1.45rem;
}

.tiny-icon {
    @include unselect;
    width: 1vmax;
}

.minuscule-icon {
    @include unselect;
    width: 1rem;
}
