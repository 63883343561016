.news-page {
    .page-container {
        padding-top: 0;

        .content-container {
            .content {
                margin-top: 2vmax;

                .content-title {
                    margin-top: 2vmax;
                    margin-bottom: 2vmax;
                }

                .news-list {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 4vmax;
                    padding-top: 2vmax;
                    padding-bottom: 6vmax;

                    .news-article {
                        width: 100%;
                    }
                }
            }
        }
    }
}
