.image-field,
.title-field,
.description-field,
.video-field,
.dimension-field {
    display: flex;
    flex-direction: column;

    label {
        font-size: 1.3rem;
        font-weight: 550;
        color: $secondary-color;
    }

    .form-field {
        font-family: $main-font;
        border: none;
        outline: none;
        resize: none;
        padding-bottom: 0.1vmax;
        overflow-y: hidden;

        &:focus {
            padding-bottom: 0;
            border-bottom: 0.15vmax solid $light-gray-2;
        }
    }
}

.selector-field {
    font-size: 1.2rem;
}

.image-field {
    width: 100%;
    gap: 0.3vmax;

    .name-container {
        width: fit-content;
        display: flex;
        gap: 1vmax;
        align-items: center;
        padding-bottom: 0.3vmax;
        cursor: pointer;

        &:hover {
            .minuscule-icon {
                filter: brightness(50%);
            }
        }
    }

    input {
        display: none;
    }

    img {
        min-width: 100%;
    }

    .no-image {
        aspect-ratio: 345/100;
    }
}
