.desahogo-page {
    .banner-img {
        max-width: 100%;
    }

    .page-container {
        .content-container {
            .content {
                margin-top: 2vmax;

                .desahogos-container {
                    display: flex;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    column-gap: 12%;
                    row-gap: 4vmax;
                    margin-bottom: 5vmax;

                    .desahogo-article {
                        position: relative;
                        flex: 43% 0;

                        .desahogo {
                            display: flex;
                            flex-direction: column;
                            gap: 1.2vmax;
                            text-decoration: none;
                            color: black;

                            .image-container {
                                width: 100%;
                                aspect-ratio: 388/195;
                                overflow: hidden;

                                .no-image {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .title {
                                font-size: 1.45rem;
                                font-weight: bold;
                            }

                            .date {
                                font-size: 1.1rem;
                                color: gray;
                            }
                        }

                        .tiny-static-plus-icon {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            cursor: pointer;
                            z-index: 3;

                            &:hover {
                                opacity: 0.8;
                                text-decoration: underline;
                            }
                        }
                    }

                    .pages-container {
                        flex: 100%;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .desahogos-container {
        flex-direction: column;
        flex-wrap: nowrap !important;
        align-items: stretch !important;
    }
}
