// COLORS

$main-color: #3d000c;
$secondary-color: #9a0018;

$gray: #747474;
$light-gray-3: #818181;
$light-gray-2: #dcdcdc;
$light-gray: #f3f3f3;

// FONTS

$main-font: "Karla", sans-serif;
$secondary-font: "Inter", sans-serif;

// BOX-SHADOW

$basic-box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);

// BORDER-RADIUS

$basic-border-radius: 0.35vmax;
