.main-sidebar {
    display: flex;
    flex-direction: column;
    gap: 3vmax;

    .profile-card {
        position: relative;
        display: flex;
        flex-direction: column;
        box-shadow: $basic-box-shadow;
        padding: 1vmax 3vmax;
        padding-bottom: 2vmax;

        .clip-container {
            position: absolute;
            right: 2vmax;
            top: -1vmax;

            .hidder {
                position: absolute;
                top: 1vmax;
                left: 0.53vmax;
                background-color: white;
                width: 2vmax;
                height: 5.3vmax;
            }
        }

        .image-container {
            width: 70%;
            align-self: center;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .title {
            font-weight: 800;
            font-size: 2.2rem;
            margin: 1vmax 0;
        }

        .description {
            max-width: 80%;
            font-size: 1.3rem;
            margin-bottom: 2vmax;
        }
    }

    .last-news-section {
        > .title {
            width: 80%;
            padding-bottom: 0.4vmax;
            border-bottom: 0.2vmax solid $light-gray-2;
            margin-bottom: 2vmax;

            h3 {
                font-size: 1.7rem;
                margin-left: 0.5vmax;
            }
        }

        .last-news {
            display: flex;
            flex-direction: column;
            gap: 2vmax;

            .last-news-redirect {
                text-decoration: none;
                color: black;

                .last-news-article {
                    gap: 0.8vmax;

                    .img-container {
                        flex: 35%;
                        aspect-ratio: 127/92;
                    }

                    .info-container {
                        flex: 65%;
                        gap: 0.3vmax;
                        font-size: 1.2rem;

                        .title {
                            font-size: 1.3rem;
                            font-weight: 700;
                            line-height: 23.38px;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .newsletter-section {
        font-family: $secondary-font;
        padding: 3vmax 2vmax;
        border: 0.1vmax solid $light-gray-2;

        #newsletter {
            display: flex;
            flex-direction: column;
            gap: 2vmax;

            .title {
                font-size: 1.8rem;
                font-weight: 700;
                color: $secondary-color;
            }

            .email-input {
                border: 0.11vmax solid $light-gray-3;
                padding: 0.7vmax 0.9vmax;
                border-radius: $basic-border-radius;
            }

            .suscribe-button {
                border: none;
                font-size: 1.3rem;
                background-color: $main-color;
                color: white;
                padding: 1vmax;
                border-radius: $basic-border-radius;
                cursor: pointer;
                transition: 0.1s;

                &:hover {
                    opacity: 0.9;
                }
            }
        }
    }

    .ads-sidebar {
        flex: 100%;
        width: 100%;
        max-width: 375px;
        align-self: center;
    }
}

@media only screen and (max-width: 800px) {
    .newsletter-section {
        margin: auto;
        max-width: 50vmax;
    }
}
