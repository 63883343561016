.ads-page {
    .content-title {
        margin-bottom: 0 !important;
    }

    .ads-list {
        display: flex;
        flex-direction: column;

        .ads-item {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 2vmax;

            &:not(:last-child) {
                border-bottom: 0.15vmax solid $light-gray-2;
            }

            &.edit {
                > * {
                    height: 9.5vmax;
                }
            }

            [class$="-field"]:not(.form-field) {
                width: 80%;
                position: relative;
                padding-top: 1.5vmax;
                flex-direction: row;
                align-items: center;

                label {
                    position: absolute;
                    top: 0;
                }

                .form-field {
                    flex: 100%;
                    border-bottom: 0.15vmax solid $light-gray-2;
                }
            }

            .image {
                flex: 25%;
                display: flex;
                justify-content: center;
                align-items: center;

                .image-field {
                    width: unset;
                }

                .image-container {
                    max-width: 10vmax;
                    max-height: 10vmax;

                    img {
                        width: unset;
                        aspect-ratio: inherit;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .no-image {
                        min-width: 10vmax;
                    }
                }
            }

            .url {
                flex: 35% 0;
                display: flex;
                justify-content: center;
                overflow: hidden;

                a {
                    display: block;
                    max-width: 80%;
                    overflow: hidden;
                }
            }

            .type {
                flex: 25%;
                display: flex;
                justify-content: center;
            }

            .operations {
                flex: 7%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 2vmax;

                > * {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                    }
                }

                .cancel {
                    color: $secondary-color;
                    font-weight: 550;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .empty {
            margin-top: 4vmax;
            margin-bottom: 2vmax;
        }

        .pagination-container {
            margin-top: 2vmax;
        }
    }
}
